<script>
    import { onMount } from "svelte";
    import { fly } from "svelte/transition";
    import { Modal } from "svelte-chota";
    import { spring } from 'svelte/motion';

    let size = spring(30);

    let initialized = false;
    let visible = false;
    let isopen = false;
    let month = "Juni 2021";
    let hostcode = "3PQK9FF7";
    let top = 75;
    let isLeft = true;
    let left = null;
    let right = null;

    function showModal()
    {
        visible = false;
        isopen = true;
    }

    onMount(() => {
        top = Math.random() * 100;
        if (top>75) top = 75;
        isLeft = Math.random() > 0.5;
        left = isLeft ? "-20px" : null;
        right = !isLeft ? "-20px" : null;
        
        fetch("/media/hostcode.txt")
            .then(txt => txt.text().then(txt => {
                //txt = txt + "\r\n\r\n\r\n"; // to be sure
                const lines = txt.match(/[^\r\n]+/g);
                month = lines[0];
                hostcode = lines[1];
                initialized = true;
                visible = true;
            }).
            catch((e) => {
                console.log("Kein Hostcode gefunden");
                visible = false;
            }));

        
    });

    $: visible = initialized && !isopen;
</script>

{#if visible}
<div id="hostcode" transition:fly="{{ x: isLeft ? -300 : 500, y: -200, duration: 500 }}" on:click={showModal} on:mouseenter="{() => size.set(60)}" on:mouseleave="{() => size.set(30)}" style="font-size: {$size}px; top: {top}%; {isLeft ? "left" : "right"} : -20px;">
    🎁
</div>
{/if}

<Modal bind:open={isopen} style="border-radius: 1em;">
    <div class="dialog">
        <p>Der Hostcode für {month} lautet</p>
        <pre>{hostcode}</pre>
        <p>
            <a href="https://www2.stampinup.com/ECWeb/default.aspx?culture=de-de&dbwsdemoid=5034744" target="_blank">Einlösen im Shop</a><br />
            <a href="/Hostcode" style="font-size:x-small;">Was ist das?</a>
        </p>
    </div>
</Modal>

<style>
    #hostcode{
        position: absolute;
        padding: 0.5em;
        background-color: #fff;
        border: 3px solid var(--color-petrol);
        border-radius: 0.5em;
        z-index: 1000;
        transform: rotate(-15deg);
        box-shadow: 0.15em 0.15em 7px 2px rgba(0, 0, 0, 0.25);
        cursor: pointer;
    }

    pre{
        font-size: 4em;
        padding: 0;
        margin: 0.25em;
    }

    #hostcode:hover{
        background-color: #ddd;
    }

    .dialog {
        font-weight: bold;
        font-size: x-large;
        padding: 2em;
        text-align: center;
    }
</style>