<script>
    import { fade, fly, slide } from 'svelte/transition';
    import { quintOut } from 'svelte/easing';

    export let name; // from props

    let breite = 10;
    let laenge = 14;
    let hoehe = 4;
    let einheit = 'cm';
    let art = 'envelope';
    let paperSize;
    let punchStart;
    let punchStart2;
    let displayEinheit = "cm";
    let faktor = 1;

    let isVisible = false;

    function toggle(){
        art = art == 'envelope' ? 'box' : 'envelope';
    }

    function berechnen() {
        if (art === 'envelope')
            calculateEnvelope();
        else
            calculateBox();
    }

    function calculateEnvelope() {
        if (!breite || !laenge) {
            alert("Ungültige Werte");
            return;
        }
        let rbreite = breite * faktor;
        let rlaenge = laenge * faktor;                
        paperSize = roundToOne(((rbreite + rlaenge) / Math.sqrt(2) + 2) / faktor); //= (a + b) ÷ √2 + 2 cm        
        punchStart = roundToOne((rlaenge / Math.sqrt(2) + 1) / faktor); // =(A7 / WURZEL(2)) + 1
    }

    function calculateBox() {
        if (!breite || !laenge || !hoehe) {
            alert("Ungültige Werte");
            return;
        }        
        let rbreite = breite * faktor;
        let rlaenge = laenge * faktor;
        let rhoehe = hoehe * faktor;        
        paperSize = roundToOne(((rbreite + rlaenge + 2 * hoehe) / Math.sqrt(2) + 2) / faktor);        //s = (a + b + 2 × c) ÷ √2 + 2 cm 
        punchStart = roundToOne((rlaenge / Math.sqrt(2) + 1) / faktor); //x1 = a ÷ √2 + 1 cm        
        punchStart2 = roundToOne(((rlaenge + 2 * rhoehe) / Math.sqrt(2) + 1) / faktor); //x2 = (a + 2 × c) ÷ √2 + 1 cm 
    }

    function roundToOne(input) {
        return Math.ceil(input * 10) / 10;
    }

    function umrechnen()
    {
        if (einheit === "cm")
        {
            breite = roundToOne(breite * 2.54);
            laenge = roundToOne(laenge * 2.54);
            hoehe = roundToOne(hoehe * 2.54);
        }else{
            breite = roundToOne(breite / 2.54);
            laenge = roundToOne(laenge / 2.54);
            hoehe = roundToOne(hoehe / 2.54);
        }
    }

    $: {
        displayEinheit = einheit === "inch" ? "\"" : "cm";
        faktor = einheit === "inch" ? 2.54 : 1.0;
        if (art === 'envelope')
            calculateEnvelope();
        else
            calculateBox();
    }
</script>

<div id="punch">
    {#if isVisible}
    <div id="rechner" transition:slide="{{delay: 0, duration: 500, easing: quintOut }}">
        <div>
        <h2>Punchboard-Rechner</h2> <button id="closeButton" on:click={() => isVisible=false} title="Schließen" transition:slide="{{delay: 0, duration: 500, easing: quintOut }}">X</button>
        </div>        
        <div style="margin-top: 2em;">
            <input type="radio" bind:group={art} value="envelope" /> Umschlag
            <input type="radio" bind:group={art} value="box" /> Box
            <br />        
            <input type="radio" bind:group={einheit} value="cm" on:change={umrechnen} /> cm
            <input type="radio" bind:group={einheit} value="inch" on:change={umrechnen} /> Zoll (inch / ")
        </div>
        <div id="cols">
            <div>
                <label for="laenge">Länge</label>
                <input id="laenge" type="number" bind:value={laenge} on:change={berechnen} /><br/>
                
                <label for="breite">Breite</label>
                <input id="breite" type="number" bind:value={breite} on:change={berechnen} /><br/>
                
                {#if art==="box"}
                <label for="breite">Höhe</label>
                <input id="hoehe" type="number" bind:value={hoehe} on:change={berechnen} />

                {/if}
            </div>
            <div style="margin-left: 1em;">
                <b>Deine Maße:</b><br />
                Seitenlänge: {paperSize}{displayEinheit}<br />
                <b>Falzen</b><br/>
                Anlegemaß: {punchStart}{displayEinheit}<br/>
                {#if art==="box"}
                Anlegemaß 2: {punchStart2}{displayEinheit}
                {/if}
            </div>
        </div>        
    </div>
    {/if}

    <button on:click={() => isVisible = !isVisible} title="Punchboard-Rechner"><i class="fa fa-calculator"></i></button> 
</div>

<style>

#punch {
    position: absolute;
    right: 50px;
    z-index: 3000;
}

#rechner{
    border: 1px solid var(--color-petrol);
    border-bottom: 3px solid var(--color-petrol);
    background-color: #fff;
    padding: 2em;
    -webkit-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.5); 
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.5);
}

#cols{
    display: flex;
    margin: 1em 0 1em 0;
}

#cols div{
    flex: 1;
    margin: 1em 0 1em 0;
    min-width: 25ex;
}

button{
    border-radius: 0 0 0.5em 0.5em;
    -webkit-box-shadow: 0px 5px 8px 1px rgba(0,0,0,0.5);
    box-shadow: 0px 5px 8px 1px rgba(0,0,0,0.5);
    position: absolute;
    right: 20px;
}

label {
    display: inline-block;
    width: 5ex;
}

input[type="number"]
{
    width: 7ex;
}

#closeButton {
    position: absolute;
    top: 0;
    right: 20px;
}
</style>