import PunchBoardCalculator from './PunchBoardCalculator.svelte';
import HostCode from './HostCode.svelte';

let domContainers = document.getElementsByTagName('PunchboardCalculator');
for (var i = 0; i < domContainers.length; i++)
{
    var div = document.createElement('DIV');
    domContainers[i].parentNode.insertBefore(div, domContainers[i]);
    const embed = new PunchBoardCalculator({        
        target: div
    });
}

domContainers = document.getElementsByTagName('HostCode');
for (var i = 0; i < domContainers.length; i++)
{
    var div = document.createElement('DIV');
    domContainers[i].parentNode.insertBefore(div, domContainers[i]);
    const embed = new HostCode({        
        target: div,
        props: { name: 'Svelte component' }
    });
}
//var div = document.createElement('DIV');
//var script = document.currentScript;
//script.parentNode.insertBefore(div, script);

